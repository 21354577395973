import styles from './Hero.module.css';
import { useState } from 'react';
import { ReactComponent as Avatars } from '../assets/avatars.svg';
import PhonePreview from '../assets/phone_preview.png';
import { ReactComponent as Logo } from '../assets/Logo.svg';

function Header({ onSubmit }) {
    return (
        <section>
            <div className={styles.background} />
            <div className={styles.root}>
                <TopNav />
                <div className={styles.mainContent}>
                    <TextContent onSubmit={(event, email) => onSubmit(event, email)} />
                    <img className={styles.heroImage} src={PhonePreview} alt='' />
                </div>
            </div>
        </section>
    );
}

function TopNav() {
    return (
        <div className={styles.navBar}>
            <Logo className={styles.logo} />
        </div>
    )
}

function TextContent({ onSubmit }) {
    return (
        <div className={styles.textContainer}>
            <HeaderText />
            <br />
            <HeaderCaption />
            <br />
            <br />
            <EmailForm onSubmit={(event, email) => onSubmit(event, email)} />
            <br />
            <br />
            <AvatarPreview />
        </div>
    )
}

function HeaderText() {
    return (
        <h1 className={styles.title}>Your own mobile app launched in minutes!</h1>
    )
}

function HeaderCaption() {
    return (
        <h2 className={styles.caption}>Engage customers, send offers, and grow repeat visits. All-in-one easy to use platform with zero development costs</h2>
    )
}

function EmailForm({ onSubmit }) {
    const [email, setEmail] = useState('');
    function submit(event) {
        onSubmit(event, email)
        setEmail('')
    }
    return (
        <form className={styles.formContainer} onSubmit={(event) => submit(event)}>
            <input
                className={styles.input}
                type='text'
                name='email'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder='Enter your email address'
            />
            <button
                className={styles.cta}
                type='submit'
                value='Submit'>Join the Waiting List</button>
        </form>
    )
}

function AvatarPreview() {
    return (
        <div className={styles.avatarContainer}>
            <Avatars />
            <h3 className={styles.avatarCaption}>400+ users applied</h3>
        </div>
    )
}


export default Header;
