import ValueProp from './components/ValueProp';
import Hero from './components/Hero';
import SetupRow from './components/SetupStep';
import Footer from './components/Footer';
import cssStyles from './App.module.css';
import { toast, ToastContainer } from 'react-toastify';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyDrWAlGTZJL4--gfXr-QlWZow-00hNABJY",
    authDomain: "loya-b6c9d.firebaseapp.com",
    projectId: "loya-b6c9d",
    storageBucket: "loya-b6c9d.firebasestorage.app",
    messagingSenderId: "323960280180",
    appId: "1:323960280180:web:4e5adbdbc35a96ee2dad21",
    measurementId: "G-EM5ZE6JDF4"
  };
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const handleHashChange = async () => {
    const hash = document.location.hash;
    logEvent(analytics, 'screen_view', { screen_name: hash.substring(1) });
  }

  handleHashChange();
  window.addEventListener("hashchange", handleHashChange);

  const onFooterClick = () => {
    window.location = "mailto:dev@hydrateapps.com?subject=Loya%20-%20Register%20Interest&body=Hi%20there%2C%0A%0AI'd%20like%20to%20register%20interest%20in%20the%20Loya%20app!"
  }

  async function onSubmit(event, email) {
    console.log('Submitted Email: ' + email)
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "a517e9d5-c2b0-4db7-999a-6c4ff8ac9437");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
    }
    toast("Email Submitted!");
    console.log('Email submitted: ', email)
  }
  return (
    <div style={styles.root}>
      <Hero onSubmit={(event, email) => onSubmit(event, email)} />
      <ToastContainer />
      <p style={styles.heroText}>Elevate Your Brand with Your Own App — Starting at Just £79/month.</p>
      <br />
      <br />
      <ValueProp />
      <br />
      <br />
      <h1 className={cssStyles.header}>How does it work?</h1>
      <br />
      <br />
      <video controls autoPlay loop muted width='800' name="Preview">
        <source src='https://dreamlightguide.dev/loyalty/loyaltyPreview.mp4' type="video/mp4" />
      </video>
      <br />
      <br />
      <SetupRow />
      <br />
      <br />
      <p style={styles.heroText}>Ready to grow your business? Start with Loya</p>
      <p style={styles.bodyText}>Your Brand. Your App. Your Loyalty</p>
      <button
        className={cssStyles.cta}
        onClick={(event) => onFooterClick()}
        type='submit'
        value='Submit'>Contact Us</button>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  heroText: {
    fontFamily: 'Unbounded',
    fontSize: '1.2em',
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: '30px'
  },
  bodyText: {
    fontFamily: 'Unbounded',
    fontSize: '1.2em',
    fontWeight: 300,
    textAlign: 'center',
    marginBottom: '60px'
  }
}


export default App;
