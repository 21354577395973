import styles from './ValueProp.module.css';
import ValueProp1 from '../assets/value_prop1.png'
import ValueProp2 from '../assets/value_prop2.png'

function ValueProp() {
    return (
        <div className={styles.container}>
            <div className={styles.valueContainer}>
                <img src={ValueProp1} className={styles.valueImage1} alt='' />
                <PropText
                    title='Launch your own in just a few clicks'
                    caption='Most loyalty programs are hard to set up, require expensive developers, or come with generic templates that don’t fit your brand. We’ve simplified things so you get the resources of large multinational chains for a fraction of the costs'
                />
            </div>
            <div className={styles.valueContainer}>
                <PropText
                    containerClass={styles.valueText2Container}
                    title='Target customers on demand'
                    caption='Notify your customers with a single click, promoting offers incentives and more. Keep you customers up to date or promote daily/weekly specials to keep them enticed'
                />
                <img src={ValueProp2} className={styles.valueImage2} alt='' />
            </div>
            <br />
            <br />
        </div>
    );
}

function PropText({ containerClass, title, caption }) {
    return (
        <div className={containerClass}>
            <h3 className={styles.valueTitle}>{title}</h3>
            <p className={styles.valueCaption}>{caption}</p>
        </div>
    )
}

export default ValueProp;