import styles from './SetupStep.module.css';

function SetupRow() {
    return (
        <div className={styles.root}>
            <SetupStep
                index='1.'
                title='Sign up and choose your template'
                caption='Pick a template that fits your brands style and needs.'
            />
            <SetupStep
                index='2.'
                title='Customise it with your logo and offers'
                caption='Make it 100% yours, no coding required.' />
            <SetupStep
                index='3.'
                title='Launch and reward your customers'
                caption='Your app is live, ready to roll with loyalty rewards!'
            />
        </div>
    )
}

function SetupStep({ index = 1, title, caption }) {
    return (
        <div className={styles.itemRoot}>
            <h2 className={styles.index}>{index}</h2>
            <div className={styles.textContainer}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.caption}>{caption}</p>
            </div>
        </div>
    )
};

export default SetupRow;
