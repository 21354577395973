import styles from './Footer.module.css';
import { ReactComponent as LogoLight } from '../assets/logo_light.svg';

function Footer() {
    return (
        <div className={styles.container}>
            <LogoLight className={styles.logo} />
            <p className={styles.captionText}>
                © 2025 Hydrate Apps Ltd. <br /> Belfast, N. Ireland
            </p>
        </div>
    )
};

export default Footer;